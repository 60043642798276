export const HOME = `/`;
export const ABOUT_US = `/about`;
export const OUR_WORK = `/work`;
export const VIDEOS = `/videos`;
export const ARTICLES = `/articles`;
export const SERVICE = `/service`;
export const ESTIMATE = '/get-estimate';
export const YELP_URL = 'https://www.yelp.com';

export const PHONE_NUMBER = `8282830449`;
